/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Archive from "@mui/icons-material/Archive";
import Unarchive from "@mui/icons-material/Unarchive";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";

function ArchiveStudyDialog({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [countMessage, setCountMessage] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(async () => {
    setOpen(true);

    if (study.studyState !== "archived") {
      setLoading(true);

      try {
        const {
          headers: {count: inboxCount},
        } = await axios({
          method: "get",
          url: "/inboxItems",
          headers: {"tzmedical-br-origin": "archive-study-count"},
          params: {
            "$enrollment.study.id$": study.id,
            completed: false,
            limit: 0,
          },
        });

        if (inboxCount === "0") {
          setCountMessage("");
        } else if (inboxCount === "1") {
          setCountMessage("There is an unanalyzed item");
        } else {
          setCountMessage(`There are ${inboxCount} unanalyzed items`);
        }
      } catch {
        // If we failed to fetch the inbox item count, don't show the message
      }

      setLoading(false);
    }
  }, [study.id, study.studyState]);
  const handleClose = React.useCallback(() => setOpen(false), []);

  //---------------------------------------------------------------------------
  // Error handling
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Helper functions
  //---------------------------------------------------------------------------
  const dispatch = useStudiesDispatch();

  const handleArchiveStudy = React.useCallback(async () => {
    setSubmitting(true);

    try {
      await axios({
        method: "post",
        url: `/studies/${study.id}/archive`,
      });

      const {
        data: [updatedStudy],
      } = await axios({
        method: "get",
        url: "/studies",
        params: {id: study.id},
      });
      dispatch({type: "updated", updatedElement: updatedStudy});
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(true);
    handleClose();
  }, [handleClose, study.id, dispatch]);

  const handleUnarchiveStudy = React.useCallback(async () => {
    setSubmitting(true);

    try {
      await axios({
        method: "post",
        url: `/studies/${study.id}/unarchive`,
      });

      const {
        data: [updatedStudy],
      } = await axios({
        method: "get",
        url: "/studies",
        params: {id: study.id},
      });
      dispatch({type: "updated", updatedElement: updatedStudy});
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(false);
    handleClose();
  }, [handleClose, study.id, dispatch]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <MenuItem
        onClick={handleOpen}
        data-cy={`${study.studyState === "archived" ? "unarchive" : "archive"}-study-button-${study.id}`}
      >
        <ListItemIcon>
          {study.studyState === "archived" ? <Unarchive fontSize="small" /> : <Archive fontSize="small" />}
        </ListItemIcon>
        <ListItemText>{study.studyState === "archived" ? "Unarchive Study" : "Archive Study"}</ListItemText>
      </MenuItem>

      <Dialog open={open} maxWidth="xs" fullWidth data-cy="confirmation-dialog">
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitle>Confirm Action</DialogTitle>

        <DialogContent>
          {/* ARCHIVE STUDY */}
          {loading && <TableLoading />}
          {!loading && study.studyState !== "archived" && (
            <Alert
              level="warning"
              message={
                <>
                  <Typography variant="inherit" pb={2}>
                    Are you sure you want to archive this study?
                  </Typography>

                  {countMessage && (
                    <Typography variant="inherit">
                      {countMessage} that will be hidden on the Analyze and Completed pages.
                    </Typography>
                  )}
                  {!countMessage && (
                    <Typography variant="inherit">
                      All items for this study will be hidden on the Analyze and Completed pages.
                    </Typography>
                  )}
                </>
              }
            />
          )}

          {/* UNARCHIVE STUDY */}
          {study.studyState === "archived" && (
            <Alert
              level="warning"
              message={
                <>
                  <Typography variant="inherit" pb={2}>
                    Are you sure you want to unarchive this study?
                  </Typography>

                  <Typography variant="inherit">
                    All items for this study will be displayed on the Analyze and Completed pages.
                  </Typography>
                </>
              }
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose} data-cy="cancel-confirmation-button">
            Cancel
          </Button>
          <LoadingButton
            data-cy="confirm-button"
            disabled={loading || submitting}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={study.studyState === "archived" ? handleUnarchiveStudy : handleArchiveStudy}
          >
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

ArchiveStudyDialog.propTypes = {
  study: PropTypes.object.isRequired,
};

export default ArchiveStudyDialog;
