/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Delete from "@mui/icons-material/Delete";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import CompactStudyInfo from "../../../shared/react/CompactStudyInfo.jsx";

function DeleteStudyDialog({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [submitting, setSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  //---------------------------------------------------------------------------
  // Error handling
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Helper functions
  //---------------------------------------------------------------------------
  const dispatch = useStudiesDispatch();

  const handleDeleteStudy = React.useCallback(async () => {
    setSubmitting(true);

    try {
      await axios({
        method: "delete",
        url: `/studies/${study.id}`,
      });

      dispatch({type: "deleted", id: study.id});
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(false);
    handleClose();
  }, [handleClose, study.id, dispatch]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <MenuItem onClick={handleOpen} data-cy={`delete-study-button-${study.id}`}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete Study</ListItemText>
      </MenuItem>

      <Dialog open={open} maxWidth="xs" fullWidth data-cy="confirmation-dialog">
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitle>Confirm Action</DialogTitle>

        <DialogContent>
          <Alert
            message={`
              Are you sure you want to delete the following study?
              ${study.followUpStudyAssociation ? ` Follow-up study ID ${study.followUpStudyAssociation.id} will also be deleted. ` : " "}
              This action cannot be undone.`}
            level="warning"
          />

          <Box mt={2}>
            <CompactStudyInfo study={study} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose} data-cy="cancel-confirmation-button">
            Cancel
          </Button>
          <LoadingButton
            data-cy="confirm-button"
            disabled={submitting}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={handleDeleteStudy}
          >
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteStudyDialog.propTypes = {
  study: PropTypes.object.isRequired,
};

export default DeleteStudyDialog;
